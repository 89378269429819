import React, { useEffect, useState } from 'react';
import './PurchasesFilter.scss';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { toCalenderDateString } from '../../../services/DateUtils';
import { vendorService } from '../../vendor/services/VendorService';
import { notificationService } from '../../../components/Notifications/Notifications';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { batchJobService } from '../services/BatchJobService';
import { CreditAdviceBatchJobCreator } from "./CreditAdviceBatchJobCreator"


export const TabStates = {
  ALL: 'ALL',
  ACCOUNTABLE: 'ACCOUNTABLE',
  BYID: 'BYID',
  EXPIRE: 'EXPIRE'
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '.8rem',
  },
  root: {
    fontSize: '.8rem',
  }
}));

const deriveTabState = (tab) => {
  switch (tab) {
    case TabStates.BYID:
      return TabStates.BYID;
    case TabStates.ACCOUNTABLE:
      return TabStates.ACCOUNTABLE;
    case TabStates.EXPIRE:
      return TabStates.EXPIRE;
    case TabStates.ALL:
    default:
      return TabStates.ALL;
  }
}

const initialSearchState = {
  startDate: new Date(),
  endDate: new Date(),
  missingForeignId: false,
  priceAboveZero: false,
  selectedVendor: null,
  vendor: null,
  shortOrLongId: ''
}

const initializeSearchState = (searchQuery) => {
  const urlQuery = new URLSearchParams(searchQuery);
  const newSearchState = {};
  if (urlQuery.has('publishDateStart')) newSearchState.startDate = new Date(urlQuery.get('publishDateStart'));
  if (urlQuery.has('publishDateEnd')) newSearchState.endDate = new Date(urlQuery.get('publishDateEnd'));
  if (urlQuery.has('missingForeignId')) newSearchState.missingForeignId = true;
  if (urlQuery.has('priceAboveZero')) newSearchState.priceAboveZero = true;
  if (urlQuery.has('shortOrLongId')) newSearchState.shortOrLongId = urlQuery.get('shortOrLongId');
  if (urlQuery.has('selectedVendors')) newSearchState.selectedVendor = urlQuery.get('selectedVendors');
  return { ...initialSearchState, ...newSearchState };
}

const initializeTabState = (searchQuery) => {
  const urlQuery = new URLSearchParams(searchQuery);
  return deriveTabState(urlQuery.get('tab'))
};

export const PurchasesFilter = ({ handleDeselectAllCheckboxes, getEntities, getPendingMetadataJobs, isUpdateMetadataButtonEnabled, triggerSearch, getPendingCreditAdviceJobs, searchQuery = window.location.search, forceUpdate }) => {

  const classes = useStyles();
  const [tabState, setTabState] = useState(initializeTabState(searchQuery));
  const [searchState, setSearchState] = useState(initializeSearchState(searchQuery));
  const [vendors, setVendors] = useState([]);
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    vendorService.getVendors()
      .then(vendors => {
        setVendors(vendors);

        const vendor = vendors.find((vendor) => vendor.id.vendorKey === searchState.selectedVendor);
        setSearchState((searchState) => ({ ...searchState, vendor: vendor || null }));
      })
      .catch(() => notificationService.error('Konnte Anbieter nicht laden, bitte aktualisiere die Seite.'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleMetadataUpdataClick = () => {
    const queryFromUrl = new URLSearchParams(searchQuery);
    const publishDateStart = queryFromUrl.get('publishDateStart')
    const publishDateEnd = queryFromUrl.get('publishDateEnd')
    const vendorKey = searchState.vendor.id.vendorKey
    const vendorName = searchState.vendor.companyName
    const data = {
      entities: getEntities(),
      type: "METADATA",
      vendorKey,
      vendorName,
      startDate: publishDateStart,
      endDate: publishDateEnd,
    }

    batchJobService.post(data).then(() => {
      handleDeselectAllCheckboxes()
      getPendingMetadataJobs()
      forceUpdate()
    })

  }

  const handleSearchAll = (evt) => {
    evt?.preventDefault();

    const searchParams = new URLSearchParams();
    if (searchState.missingForeignId) searchParams.set('missingForeignId', 'true');
    if (searchState.priceAboveZero) searchParams.set('priceAboveZero', 'true');
    if (searchState.startDate) searchParams.set('publishDateStart', toCalenderDateString(searchState.startDate));
    if (searchState.endDate) searchParams.set('publishDateEnd', toCalenderDateString(searchState.endDate));
    if (searchState.selectedVendor) searchParams.set('selectedVendors', searchState.selectedVendor);

    triggerSearch(searchParams, TabStates.ALL)
  }

  const handleSearchAccountable = (evt) => {
    evt?.preventDefault();

    const searchParams = new URLSearchParams();
    if (searchState.priceAboveZero) searchParams.set('priceAboveZero', 'true');
    if (searchState.startDate) searchParams.set('publishDateStart', toCalenderDateString(searchState.startDate));
    if (searchState.endDate) searchParams.set('publishDateEnd', toCalenderDateString(searchState.endDate));
    if (searchState.selectedVendor) searchParams.set('selectedVendors', searchState.selectedVendor);

    triggerSearch(searchParams, TabStates.ACCOUNTABLE)
  }

  const handleSearchExpire = (evt) => {
    evt?.preventDefault();

    const searchParams = new URLSearchParams();
    if (searchState.selectedVendor) searchParams.set('selectedVendors', searchState.selectedVendor);
    if (searchState.priceAboveZero) searchParams.set('priceAboveZero', 'true');

    triggerSearch(searchParams, TabStates.EXPIRE)
  }

  const handleSearchById = (evt) => {
    evt?.preventDefault();

    const searchParams = new URLSearchParams();
    if (searchState.shortOrLongId) searchParams.set('shortOrLongId', searchState.shortOrLongId);

    triggerSearch(searchParams, TabStates.BYID)
  }

  const handleClearSearch = () => {

    setSearchState({ ...initialSearchState });
    const searchParams = new URLSearchParams();
    searchParams.set('publishDateStart', toCalenderDateString(new Date()));
    searchParams.set('publishDateEnd', toCalenderDateString(new Date()));
    triggerSearch(searchParams, tabState)
  }



  return (
    <div className='purchaseFilter' data-testid='purchaselist--filter'>

      <div className='purchaseFilterTabs'>
        <Button
          className={`purchaseFilterTabs--usages ${tabState === TabStates.ALL ? 'purchaseFilterTabs--active' : ''}`}
          data-testid='purchaseFilterTabs--all'
          name='filterTabUsages'
          onClick={() => setTabState(TabStates.ALL)}
        >
          Verwendungen
        </Button>

        <Button
          className={`purchaseFilterTabs--usages ${tabState === TabStates.ACCOUNTABLE ? 'purchaseFilterTabs--active' : ''}`}
          data-testid='purchaseFilterTabs--accountable'
          name='filterTabCreditAdvice'
          onClick={() => setTabState(TabStates.ACCOUNTABLE)}
        >
          Abrechnungsrelevante Verwendungen
        </Button>

        <Button
          className={`purchaseFilterTabs--usages ${tabState === TabStates.EXPIRE ? 'purchaseFilterTabs--active' : ''}`}
          data-testid='purchaseFilterTabs--expire'
          name='filterTabCreditAdvice'
          onClick={() => setTabState(TabStates.EXPIRE)}
        >
          Auslaufende Verwendungen
        </Button>

        <Button
          className={`purchaseFilterTabs--usages ${tabState === TabStates.BYID ? 'purchaseFilterTabs--active' : ''}`}
          data-testid='purchaseFilterTabs--byid'
          name='filterSearchById'
          onClick={() => setTabState(TabStates.BYID)}
        >
          Bildersuche
        </Button>
      </div>

      <Card className='purchaseFilterCard'>

        {tabState === TabStates.ALL && (
          <form className='purchaseFilterUsagesWrapper' data-testid='purchaseFilterUsagesWrapper' onSubmit={handleSearchAll}>
            <div className='purchaseFilterUsagesWrapper__datePickers'>
              <div className='purchaseFilterUsagesWrapper__datePickers__startDatePicker'
                data-testid="purchaseFilter--startdate">
                <DatePicker
                  setSelectedDate={(date) => setSearchState({ ...searchState, startDate: date })}
                  selectedDate={searchState.startDate}
                  datePickerId={'startDatePicker'}
                  id='startDatePickerUsages'
                  classes={{ label: classes.label }}
                />
              </div>
              <div className='purchaseFilterUsagesWrapper__datePickers__endDatePicker'
                data-testid="purchaseFilter--enddate">
                <DatePicker
                  setSelectedDate={(date) => setSearchState({ ...searchState, endDate: date })}
                  selectedDate={searchState.endDate}
                  datePickerId={'endDatePicker'}
                  id='endDatePickerUsages'
                />
              </div>
            </div>
            <div className='purchaseFilterUsagesWrapper__vendorAndTogglesSection'>
              <div className='purchaseFilterUsagesWrapper__vendorPicker'>
                <Autocomplete
                  value={searchState.vendor}
                  onChange={(_, vendor) => setSearchState({
                    ...searchState,
                    selectedVendor: vendor?.id?.vendorKey,
                    vendor: vendor
                  })}
                  data-testid='purchaselist--filter__vendors'
                  ListboxProps={{ 'data-testid': 'vendorOptionsList' }}
                  id='tags-standard'
                  loading={isLoading}
                  disabled={isLoading}
                  options={vendors}
                  loadingText={''}
                  noOptionsText={'Keine Anbieter'}
                  getOptionLabel={vendor => vendor.companyName}
                  classes={{ root: classes.root }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={`Anbieter (${vendors.length})`}
                    />
                  )}
                />
              </div>
              <div className='purchaseFilterUsagesWrapper__togglesWrapper'>
                <FormGroup column='true' className='purchaseFilterUsagesToggles'>
                  <FormControlLabel
                    data-testid='purchaselist--filter__missingForeignId'
                    className={'nowrap'}
                    classes={{ label: classes.label }}
                    control={
                      <Switch
                        checked={searchState.missingForeignId}
                        onChange={(_, value) => setSearchState({ ...searchState, missingForeignId: value })}
                        name='missingForeignIdChecked'
                        color='primary'
                      />
                    }
                    label='Fehlende Fremd-ID'
                  />
                </FormGroup>
                <FormGroup column='true' className='purchaseFilterUsagesToggles'>
                  <FormControlLabel
                    data-testid='purchaselist--filter__priceAboveZero'
                    classes={{ label: classes.label }}
                    control={
                      <Switch
                        checked={searchState.priceAboveZero}
                        onChange={(_, value) => setSearchState({ ...searchState, priceAboveZero: value })}
                        name='priceAboveZeroChecked'
                        color='primary'
                      />
                    }
                    label='honorarpflichtig'
                  />
                </FormGroup>
              </div>
            </div>
            <div className='purchaseFilterUsagesButtons'>
              <Button
                type='submit'
                data-testid='purchaselist--filter__search'
                onClick={handleSearchAll}
                className='purchaseFilterCreditAdviceButtons--search'
              >
                Suchen
              </Button>

              <Button
                data-testid='purchaselist--filter__reset'
                onClick={handleClearSearch}
                className='purchaseFilterCreditAdviceButtons--reset'
              >
                Zurücksetzen
              </Button>

            </div>
            <div className='purchaseFilterCreateBatchJobButton'>
              <Button
                disabled={!isUpdateMetadataButtonEnabled}
                data-testid='purchaselist--filter__create'
                onClick={handleMetadataUpdataClick}
                className='purchaseFilterCreateBatchJobButton--button'
              >
                Metadaten aktualisieren
              </Button>
            </div>
          </form>
        )}

        {tabState === TabStates.ACCOUNTABLE && (

          <form className='purchaseFilterCreditAdviceWrapper' data-testid='purchaseFilterCreditAdviceWrapper' onSubmit={handleSearchAccountable}>

            <div className='purchaseFilterUsagesWrapper__datePickers'>
              <div className='purchaseFilterUsagesWrapper__datePickers__startDatePicker'>
                <DatePicker
                  setSelectedDate={(date) => setSearchState({ ...searchState, startDate: date })}
                  selectedDate={toCalenderDateString(searchState.startDate)}
                  datePickerId={'startDatePicker'}
                  id='startDatePickerUsages'
                  classes={{ label: classes.label }}
                />
              </div>
              <div className='purchaseFilterUsagesWrapper__datePickers__endDatePicker'>
                <DatePicker
                  setSelectedDate={(date) => setSearchState({ ...searchState, endDate: date })}
                  selectedDate={toCalenderDateString(searchState.endDate)}
                  datePickerId={'endDatePicker'}
                  id='endDatePickerUsages'
                />
              </div>
            </div>
            <div className='purchaseFilterUsagesWrapper__vendorAndTogglesSection'>
              <div className='purchaseFilterUsagesWrapper__vendorPicker'>
                <Autocomplete
                  value={searchState.vendor}
                  data-testid='purchaselist--filter__vendors'
                  onChange={(_, vendor) => setSearchState({
                    ...searchState,
                    selectedVendor: vendor?.id?.vendorKey,
                    vendor: vendor
                  })}
                  ListboxProps={{ 'data-testid': 'vendorOptionsList' }}
                  id='tags-standard'
                  options={vendors}
                  disabled={isLoading}
                  loading={isLoading}
                  noOptionsText={'Keine Anbieter'}
                  loadingText={''}
                  getOptionLabel={vendor => vendor.companyName}
                  classes={{ root: classes.root }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={`Anbieter (${vendors.length})`}
                    />
                  )}
                />
              </div>

              <FormGroup column='true' className='purchaseFilterUsagesToggles'>
                <FormControlLabel
                  data-testid='purchaselist--filter__priceAboveZero'
                  classes={{ label: classes.label }}
                  control={
                    <Switch
                      checked={searchState.priceAboveZero}
                      onChange={(_, value) => setSearchState({ ...searchState, priceAboveZero: value })}
                      name='priceAboveZeroChecked'
                      color='primary'
                    />
                  }
                  label='honorarpflichtig'
                />
              </FormGroup>
            </div>

            <div className='purchaseFilterUsagesButtons'>
              <Button
                type='submit'
                data-testid='purchaselist--filter__search'
                onClick={handleSearchAccountable}
                className='purchaseFilterCreditAdviceButtons--search'
              >
                Suchen
              </Button>

              <Button
                data-testid='purchaselist--filter__reset'
                onClick={handleClearSearch}
                className='purchaseFilterCreditAdviceButtons--reset'
              >
                Zurücksetzen
              </Button>
            </div>
            <div className='purchaseFilterCreateCreditAdvice'>
              <CreditAdviceBatchJobCreator vendors={vendors} searchQuery={searchQuery} triggerSearch={triggerSearch} forceUpdate={forceUpdate} getPendingCreditAdviceJobs={getPendingCreditAdviceJobs} />
            </div>
          </form>
        )}

        {tabState === TabStates.EXPIRE && (
          <form className='purchaseFilterUsagesWrapper' data-testid='purchasesFilterSearchExpireWrapper' onSubmit={handleSearchExpire}>

            <div className='purchaseFilterUsagesWrapper__label'>Innerhalb 7 Tagen auslaufend</div>
            <div className='purchaseFilterUsagesWrapper__vendorAndTogglesSection'>
              <div className='purchaseFilterUsagesWrapper__vendorPicker'>
                <Autocomplete
                  value={searchState.vendor}
                  data-testid='purchaselist--filter__vendors'
                  onChange={(_, vendor) => setSearchState({
                    ...searchState,
                    selectedVendor: vendor?.id?.vendorKey,
                    vendor: vendor
                  })}
                  ListboxProps={{ 'data-testid': 'vendorOptionsList' }}
                  id='tags-standard'
                  options={vendors}
                  disabled={isLoading}
                  loading={isLoading}
                  noOptionsText={'Keine Anbieter'}
                  loadingText={''}
                  getOptionLabel={vendor => vendor.companyName}
                  classes={{ root: classes.root }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='standard'
                      label={`Anbieter (${vendors.length})`}
                    />
                  )}
                />
              </div>
              <div className='purchaseFilterUsagesWrapper__togglesWrapper'>
                <FormGroup column='true' className='purchaseFilterUsagesToggles'>
                  <FormControlLabel
                    data-testid='purchaselist--filter__priceAboveZero'
                    classes={{ label: classes.label }}
                    control={
                      <Switch
                        checked={searchState.priceAboveZero}
                        onChange={(_, value) => setSearchState({ ...searchState, priceAboveZero: value })}
                        name='priceAboveZeroChecked'
                        color='primary'
                      />
                    }
                    label='honorarpflichtig'
                  />
                </FormGroup>
              </div>

              <div className='purchaseFilterSeachByIdButtons'>
                <Button
                  type='submit'
                  data-testid='purchaselist--filter__search'
                  onClick={handleSearchExpire}
                  className='purchaseFilterUsagesButtons--search'
                >
                  Suchen
                </Button>

                <Button
                  data-testid='purchaselist--filter__reset'
                  onClick={handleClearSearch}
                  className='purchaseFilterUsagesButtons--reset'
                >
                  Zurücksetzen
                </Button>
              </div>
            </div>
          </form>
        )}

        {tabState === TabStates.BYID && (
          <form className='purchaseFilterSearchByIdWrapper' data-testid='purchasesFilterSearchByIdWrapper' onSubmit={handleSearchById}>

            <FormControl fullWidth>
              <Input
                value={searchState.shortOrLongId}
                placeholder="Asset ID"
                onChange={(evt) => setSearchState({ ...searchState, shortOrLongId: evt.target.value })}
                variant="outlined"
                inputProps={{ 'data-testid': 'purchaseFilter--damAssetId' }}
              />
            </FormControl>

            <div className='purchaseFilterSeachByIdButtons'>
              <Button
                type='submit'
                disabled={!searchState.shortOrLongId}
                data-testid='purchaselist--filter__search'
                onClick={handleSearchById}
                className='purchaseFilterCreditAdviceButtons--search'
              >
                Suchen
              </Button>

              <Button
                data-testid='purchaselist--filter__reset'
                onClick={handleClearSearch}
                className='purchaseFilterCreditAdviceButtons--reset'
              >
                Zurücksetzen
              </Button>
            </div>
          </form>
        )}
      </Card>
    </div>
  );
}
