import {HTTP_STATUS_CONFLICT, HTTP_STATUS_METHOD_NOT_ALLOWED, httpClient} from '../../../infrastructure/HttpClient';
import {Metadata} from '../models/Metadata';

const removeDoubleDoubleQuotes = (text) => {
  return text.substr(1, text.length - 2);
}

// TODO: can be removed after 1.1.2022 as new calculations no longer have multiple strategies see https://id-jira.axelspringer.de/browse/NCC-2548 for details
export const resolutionStrategyLiteral = (strategy) => {
  switch (strategy) {
    case 'BY_DAM':
      return '(via DAM)';
    case 'BY_VENDOR':
      return '(via Anbieter)';
    default:
      return '';
  }
}

class AssetService {
  static URL_MAPPING = '/usage-catalog/api/assets';

  fetchMetadataWithDamAssetUrl(asset) {
    const payload = {damAssetUrl: asset?.metadata?.damAssetUrl ?? ""};
    return httpClient
      .post(`${AssetService.URL_MAPPING}/metadata/${asset?.assetId?.damAssetId ?? ""}`, payload)
      .then(response => {
        return {
          version: removeDoubleDoubleQuotes(response.headers.etag),
          metadata: Metadata.fromJson(response.data)
        }
      })
  }

  getAssetThumbnailUrl(damAssetUrl, assetId){
    const payload = {damAssetUrl: damAssetUrl ?? ""};
    return httpClient
      .post(`${AssetService.URL_MAPPING}/metadata/thumbnailUrl/${assetId}`, payload)
      .then(response => {
        return response.data
      })
  }

  refreshMetadata(assetId, version, damAssetUrl) {
    const payload = {damAssetUrl: damAssetUrl ?? ""};
    return httpClient
      .post(`${AssetService.URL_MAPPING}/metadata/${assetId.damAssetId}/${assetId.purchaseOrdinal}?byurl`, payload, {headers: {'If-Match': version}})
      .catch(response => {
        return Promise.reject(
          {
            version: response.headers.etag,
            metadata: Metadata.fromJson(response.data),
            isConflict: response.statuscode === HTTP_STATUS_CONFLICT,
            isNotAllowed: response.statuscode === HTTP_STATUS_METHOD_NOT_ALLOWED
          })
      })
  }

  setRestrictionNote(assetId) {
    return httpClient
      .put(`${AssetService.URL_MAPPING}/${assetId.damAssetId}/restrictionNote`)
  }

  overrideVendor(assetId, vendor) {
    return httpClient
      .post(`${AssetService.URL_MAPPING}/vendor/${assetId.damAssetId}`, vendor)
  }
  
  updatePurchasePrice(damAssetId, price){
    return httpClient
    .put(`${AssetService.URL_MAPPING}/metadata/${damAssetId}/purchasePrice`, price)
  }

}

export const assetService = new AssetService();
