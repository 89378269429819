import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Delete } from '../../../assets/Icons/CreditAdviceIcons';
import { toDateString } from '../../../services/DateUtils';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { departmentService } from '../../department/services/DepartmentService';
import SpinnerInline from '../../../components/Spinner/SpinnerInline';
import { PriceCurrencyLiteral } from '../../../services/MoneyUtils';
import { TextareaAutosize } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';



const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#FF0954',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const CreditLineItem = React.memo((props) => {

  const { creditLineItem, index, isLocked, isSelected, handleSelectItem, handleDeleteCreditLineItem, handleEditCreditLineItemCaption } = props;

  const [loadingDepartment, setLoadingDepartment] = useState(true);
  const [localCreditLineItem, setLocalCreditLineItem] = useState(creditLineItem);
  const [isEditMode, setIsEditMode] = useState(false)
  const [caption, setCaption] = useState(creditLineItem.caption)

  const departmentKey = creditLineItem.department.departmentKey;

  useEffect(() => {
    if (departmentKey) {
      departmentService.fetchDepartmentByKeyCached(departmentKey)
        .then(response => {
          setLocalCreditLineItem((localCreditLineItem) => ({
            ...localCreditLineItem,
            department: {
              departmentKey: departmentKey,
              name: response.name
            }
          }));
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoadingDepartment(false);
        });
    } else {
      setLocalCreditLineItem((localCreditLineItem) => ({
        ...localCreditLineItem,
        department: {
          departmentKey: departmentKey,
          name: 'Ressort noch nicht ermittelt'
        }
      }));
    }
  }, [departmentKey, creditLineItem.department.name]);

  const handleClick = () => {
    !isLocked && setIsEditMode(true)
  }

  const handleClickAway = () => {
    if (caption && (caption !== creditLineItem.caption)) {
      handleEditCreditLineItemCaption(creditLineItem, caption)
      setIsEditMode(false)
    }
    if(caption && (caption === creditLineItem.caption)){
      setIsEditMode(false)
    }

  };

  return (
    <TableRow
      key={index}
      hover={true}
      data-testid='creditadvicedetail--creditLineItem'
    >
      <TableCell className='creditadvicedetail__actions'>
        <CustomCheckbox
          className='creditadvicedetail__checkbox'
          data-testid='creditadvicedetail--checkbox'
          disabled={isLocked}
          checked={isSelected(creditLineItem)}
          onClick={() => handleSelectItem(creditLineItem)}
          inputProps={{ 'aria-labelledby': creditLineItem.id }}
        />
        {!isLocked ? (
          <span
            className='creditadvicedetail__delete'
            data-testid='creditadvicedetail--delete'
            onClick={
              (event) => {
                event.stopPropagation();
                return handleDeleteCreditLineItem(creditLineItem);
              }
            }
          >
            <Delete />
          </span>
        ) : (
          <span className='creditadvicedetail__delete--disabled' data-testid='creditadvicedetail--delete__disabled'>
            <Delete />
          </span>
        )}
      </TableCell>
      <TableCell>
        <span data-testid='creditadvicedetail--id'>{creditLineItem.assetId.originDamId || '-'} / {creditLineItem.foreignId || '-'}</span>
      </TableCell>
      <TableCell>

        <div className="creditadvicedetail__heading" data-testid='creditadvicedetail--caption' onClick={handleClick} > {isEditMode ?
          <ClickAwayListener onClickAway={handleClickAway}>
            <TextareaAutosize
              data-testid='creditadvicedetail--caption__textarea'
              id='headline'
              variant='outlined'
              type='text'
              value={caption}
              minRows={3}
              maxLength={2048}
              onChange={(e) => { setCaption(e.target.value) }}
              style={{ width: "100%", padding: "8px", fontSize: "15px", maxLength: 500 }}
            />
          </ClickAwayListener>
          : <span data-testid='creditadvicedetail--caption__span' > {caption || '-'}</span>
        }
        </div>
      </TableCell>
      <TableCell>
        <span data-testid='creditadvicedetail--department'>{loadingDepartment ?
          <SpinnerInline /> : localCreditLineItem.department.name || '-'}</span>
      </TableCell>
      <TableCell>
        <div
          data-testid='creditadvicedetail--usageTime'>{toDateString(creditLineItem.usageTime.start) || '-'} - {toDateString(creditLineItem.usageTime.end) || '-'}</div>
      </TableCell>
      <TableCell align='right' className='creditadvicedetail--alignRight'>
        <PriceCurrencyLiteral price={creditLineItem.price.value} currency={creditLineItem.price.currency} testId='creditadvicedetail--price' />
      </TableCell>
    </TableRow>
  );
});

export default CreditLineItem;
