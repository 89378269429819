import React, {useEffect, useState} from 'react';
import {vendorService} from '../../vendor/services/VendorService';
import SpinnerInline from '../../../components/Spinner/SpinnerInline';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import {toDateString} from '../../../services/DateUtils';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {prefixWithTenantDomain} from '../../../services/TenantService';
import {PurchaseStatusIndicator} from './PurchaseStatusIndicator';
import {PriceCurrencyLiteral} from '../../../services/MoneyUtils';
import UseAssetImage from '../../../hooks/UseAssetImage';
import DepartmentToBeCharged from './DepartmentToBeCharged';
import {VendorLink} from './VendorLink'
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: 600,
  },
  title: {
    flex: '1 1 100%',
  },
  table: {
    minWidth: 650,
  },
  usages: {
    minWidth: 300,
  }
}));
const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#FF0954',
    },
  },
  checked: {},
});


export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

function descSnipper(description) {
  if (description) {
    return description.length > 200 ? description.substring(0, 200) + '...' : description
  } else {
    return '-'
  }
}

const PurchaseRow = React.memo(({
                                  purchase,
                                  index,
                                  handleAssetDetails,
                                  handleSelectPurchase,
                                  isSelected,
                                  shouldShowCheckbox,
                                  shouldDisableCheckbox
                                }) => {

  const classes = useStyles();
  const [loadingVendor, setLoadingVendor] = useState(true);
  const [vendor, setVendor] = useState({...purchase.vendor})

  useEffect(async () => {
    if (vendor && vendor.vendorKey && !vendor.vendorName) {
      await vendorService.getVendorByIdCached(vendor.vendorKey)
        .then((response) => setVendor((vendor) => ({...vendor, vendorName: response?.personalData?.companyName})))
        .catch(() => {/* nothing to be done */
        })
        .finally(() => setLoadingVendor(false));
    } else {
      setLoadingVendor(false);
    }
  }, [vendor.vendorKey]);

  const usages = purchase.usages || [];

  let usageItems = usages.map((u, index) => {
    return (
      <li key={index}>
        <span data-testid="purchaseTable--usage__departmentName">{u.department.name || '-'}</span>
        <span> | </span>
        <span
          data-testid="purchaseTable--usage__placementTime">{toDateString(u.placementTime) || '-'}</span>
        <span> | </span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={u.documentPath ? prefixWithTenantDomain(u.documentPath) : '-'}
          data-testid="purchaseTable--usage__documentPath"
        >
          Artikel
        </a>
      </li>
    );
  });

  const restrictionNoteSet = purchase && purchase.restrictionNoteSet;

  return (
    <>
      <TableRow
        key={index}
        hover={true}
        data-testid="purchaseTable--item"
        className={purchase.hasExcessivePrice ? 'purchaseTable__assetExcessivePrice' : ''}
      >
        <TableCell>
          <div className="purchaseTable__statusAndCheckbox">
            {shouldShowCheckbox && <CustomCheckbox
              className="creditadvicedetail__checkbox"
              data-testid="purchase_list_all__checkbox"
              disabled={shouldDisableCheckbox}
              checked={isSelected(purchase.assetId.damAssetId)}
              onClick={() => handleSelectPurchase(purchase.assetId.damAssetId)}

            />}
            <div className="purchaseTable__statusIcons">
              <PurchaseStatusIndicator purchase={purchase}/>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="purchaseTable__assetImg" data-testid="purchaseTable--thumbnailButton"
               onClick={() => handleAssetDetails(purchase, vendor)}>
            <UseAssetImage
              thumbnailUrl={purchase?.metadata?.thumbnailUrl ?? ''}
              damAssetUrl={purchase?.metadata?.damAssetUrl ?? ''}
              assetId={purchase?.assetId?.damAssetId ?? ''}
              testIdAvailable={'purchaseTable--thumbnail'}
              restrictionNoteSet={restrictionNoteSet}
              isPurchaseList={true}
              isCollage={purchase.isCollage}
            />
          </div>
        </TableCell>
        <TableCell>
          <div data-testid="purchaseTable--assetDamId">{purchase.assetId.damAssetId || '-'}</div>
          {purchase.metadata.shortId &&
            <div>
              <div style={{'userSelect': 'none'}}>-----</div>
              <div>{purchase.metadata.shortId}</div>
            </div>
          }
        </TableCell>
        <TableCell>
          <Tooltip title={purchase.metadata.credit || ''} enterDelay={300} leaveDelay={200} placement="top">
            <div className="purchaseTable__credit"
                 data-testid="purchaseTable--credit">{purchase.metadata.credit || '-'}</div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={purchase.metadata.foreignId || ''} enterDelay={300} leaveDelay={300} placement="top">
            <div
              className="purchaseTable__assetForeignId"
              data-testid="purchaseTable--assetForeignId"
            >
              {purchase.metadata.foreignId || '-'}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <DepartmentToBeCharged name={purchase.departmentToBeCharged.name}/>
        </TableCell>
        <TableCell>
          {loadingVendor ? <SpinnerInline/> : <VendorLink vendorKey={vendor.vendorKey} vendorName={vendor.vendorName}/>}
        </TableCell>
        <TableCell>
          <Tooltip title={purchase.metadata.objectDescription || ''} enterDelay={300} leaveDelay={300} placement="top">
            <div
              className="purchaseTable__objectDescription"
              data-testid="purchaseTable--objectDescription"
            >
              {descSnipper(purchase.metadata.objectDescription)}
            </div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={purchase.priceResolutionStrategy || ''} enterDelay={300} leaveDelay={200} placement="top"
                   arrow>
            <div className="nowrap" data-testid="purchaseTable--price">
              <PriceCurrencyLiteral price={purchase.price.value} currency={purchase.price.currency}/>
            </div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={purchase.onlineTimeCalculationStrategy || ''} enterDelay={300} leaveDelay={200}
                   placement="top" arrow>
            <div data-testid="purchaseTable--onlineTime">
              <span data-testid="purchaseTable--onlineTimeStart">{toDateString(purchase.onlineTimeStart) || '-'}</span>
              &nbsp;-&nbsp;
              <span data-testid="purchaseTable--onlineTimeEnd">{toDateString(purchase.onlineTimeEnd) || '-'}</span>
            </div>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.usages}>
          <ul className={'list'}>{usageItems}</ul>
        </TableCell>
      </TableRow>
    </>
  );
});

export default PurchaseRow;
